<template>
  <div :class="prefixCls" id="kfgs">
    <div :class="`${prefixCls}-carousel`">
      <Carousel :carouselData="swipeList" height="600px">
      </Carousel>
    </div>
      <main :class="`${prefixCls}-content`">
        <aside :class="`${prefixCls}-navs`">
            <div :class="`${prefixCls}-navs-item ${active === '/press/overview'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/press/overview')">
                研发概述
            </div>
            <div :class="`${prefixCls}-navs-item ${active === '/press/strategy'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/press/strategy')">
                研发战略
            </div>
            <div :class="`${prefixCls}-navs-item ${active === '/press/talents'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/press/talents')">
                研究成果
            </div>
<!--            <div :class="`${prefixCls}-navs-item ${active === '/press/new'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('/press/new')">-->
<!--                研发专利-->
<!--            </div>-->
        </aside>
        <transition name="custom"
                    mode="out-in"
                    appear>
            <router-view></router-view>
        </transition>
      </main>
<!--    <Overview />-->
<!--    <Strategy />-->
<!--    <innovative-talents />-->
  </div>
</template>

<script>
import Carousel from '../../components/Carousel/index.vue'
import { carouselMixin } from '../../mixin/carousel'
// import { getNewsCenter } from '../../api/press'
// import Overview from './components/RDOverview.vue'
// import Strategy from './components/RDStrategy.vue'
// import InnovativeTalents from './components/InnovativeTalents.vue'
import { getBannerByChannelId } from '@/api'
export default {
  name: 'Press',
  components: {
    // InnovativeTalents,
    Carousel
    // Overview,
    // Strategy
  },
  data () {
    return {
      active: '',
      prefixCls: this.$prefix + '-press',
      pressData: {},
      swipeList: []
    }
  },
  watch: {
    $route: function (val) {
      this.active = val.path
    }
  },
  activated () {
    this.active = this.$route.path
  },
  mixins: [carouselMixin],
  created () {
    this.active = this.$route.path
    this.getBannerByChannelId()
  },
  methods: {
    toggleNav (path) {
      if (this.active === path) {
        return false
      }
      this.active = path
      this.$router.push(path)
    },
    getBannerByChannelId () {
      getBannerByChannelId(4).then(({ data }) => {
        this.swipeList = data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -press;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 40px;
  &-carousel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  &-content{
    display: flex;
    flex-direction: row;
    width: $content-width;
    min-height: 800px;
  }
  &-navs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 25px;
    margin-top: 50px;
    font-size: 20px;
    &-active{
      background-color: #1E2A48 !important;
      color: #fff !important;
    }
    &-item{
      color: #000;
      text-align: center;
      width: 240px;
      background-color: #F5F7FA;
      height: 80px;
      line-height: 80px;
      cursor: pointer;
    }
  }
}
</style>
